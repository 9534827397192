* {
  box-sizing: border-box;
}

.navigation-wrapper {
  position: relative;
}

.arrow__button {
  width: 45px;
  height: 45px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
  position: absolute;
  top: 50%;
  transform-origin: center center;
  transition: all 300ms ease;
}

.arrow__button:hover {
  transform: scale(1.1);
  box-shadow: 5px 5px 30px 0 rgba(0, 0, 0, 0.2);
}

.arrow__icon {
  transition: all 300ms ease;
}

.arrow__button:hover .arrow__icon {
  transform: scale(1.1);
}

.arrow__button--left {
  left: -4px;
}

.arrow__button--right {
  right: -4px;
}

