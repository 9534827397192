.skeleton {
  background-color: #dddbdd;
  display: inline-block;
  overflow: hidden;
  position: relative;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton::after {
  animation: shimmer 2s infinite;
  background-image: linear-gradient(
    90deg,
    hsla(0, 0%, 100%, 0),
    hsla(0, 0%, 100%, 0.2) 20%,
    hsla(0, 0%, 100%, 0.5) 60%,
    hsla(0, 0%, 100%, 0)
  );
  transform: translateX(-100%);
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* HOT COLLECTIONS */

.skeleton-bg {
  width: 100%;
  height: 190px;
}

.skeleton__profile--image {
  border-radius: 50%;
  height: 54px;
  width: 54px;
}

.nft_coll_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.name-skeleton {
  width: 100px;
  height: 20px;
}

.id-skeleton {
  width: 60px;
  height: 20px;
  margin-top: 8px;
}

/* NEW ITEMS */

.new-items__container--skeleton {
  height: 450px;
}

.new-items__skeleton-bg {
  width: 94%;
  height: 350px;
  margin-top: 12px;
}

.new-items__name--skeleton {
  width: 80%;
  height: 30px;
}

.new-items__info--skeleton {
  margin-left: 12px;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.new-items__profile--skeleton {
  position: absolute;
  top: 48px;
  left: 12px;
}

.new-items__name-and-id--skeleton {
  max-width: 180px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.new-items__likes--skeleton {
  width: 30px;
  height: 15px;
  margin-right: 16px;
}

/* TOP SELLERS */

.top-sellers__profile--skeleton {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  overflow: hidden;
}

.author_list_pp i {
  bottom: 6px;
  right: 4px;
}

.top-sellers__name--skeleton {
  width: 100px;
  height: 20px;
}

.top-sellers__price--skeleton {
  width: 40px;
  height: 20px;
  margin-top: 8px;
}

/* AUTHOR */

.author__bg--skeleton{
  min-height: 360px;
  width: 100%;
}

.author__user--skeleton-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.author__all-info--skeleton-container {
  display: flex;
  align-items: center;
  margin-top: 40px;
  margin-left: 40px;
}

.author__profile--skeleton {
  width: 150px;
  height: 150px;
  border-radius: 500%;
  margin-right: 24px;
}

.author__info--skeleton {
  display: flex;
  flex-direction: column;
}

.author__name--skeleton {
  width: 200px;
  height: 20px;
}

.author__handle--skeleton {
  width: 100px;
  height: 10px;
  margin: 12px 0;
}

.author__wallet--skeleton {
  width: 250px;
  height: 16px;
}

.author__follow--skeleton-container {
  width: 150px;
  height: 40px;
  margin-right: 40px;
}

@media (max-width: 768px) {
  .author__user--skeleton-container {
    flex-direction: column;
  }
  .author__follow--skeleton-container {
    margin-top: 24px;
  }
}

/* ITEM DETAILS */

.item__skeleton {
  max-width: 630px;
  height: 630px;
  width: 100%;
}

.item__title--skeleton {
  max-width: 450px;
  width: 100%;
  height: 40px;
}

.item__views--skeleton,
.item__likes--skeleton {
  height: 30px;
}

.item__description--skeleton {
  max-width: 600px;
  width: 100%;
  height: 60px;
}

.item__person--skeleton {
  display: flex;
  align-items: center;
}

.item__profile--skeleton {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 12px;
}

.item__name--skeleton {
  width: 100px;
  height: 16px;
}

@media (max-width: 1400px) {
  .item__skeleton {
    margin: 0 40px 40px 40px;
  }
  .item__row--skeleton {
    display: flex;
    justify-content: center;
  }
  .item__info--skeleton {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .item__row--skeleton {
    margin: 0 20px;
  }
}
